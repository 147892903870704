:root {
  --slightly-darker-dark: rgba(27, 31, 34, 1);
}

#primary-nav {
  background-color: var(--slightly-darker-dark)
}

#nav-logo {
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.video-player {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.video-player video {
  width: 100%;
}

.controls {
  background-color: var(--slightly-darker-dark);
  display: flex;
  width: 100%;
  max-width: 750px;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}

.controls select {
  color: var(--bs-body-color);
  background-color: var(--slightly-darker-dark);
  background: none;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.controls select option {
  color: var(--bs-body-color);
  background-color: var(--slightly-darker-dark);
}

.controls button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.controls button i {
  font-size: 30px;
  color: var(--bs-body-color)
}

.controls input[type="file"] {
  display: none;
}

#main-scrubber {
  width: 350px;
}

#small-scrubber {
  width:200px;
}

.chart {
  background-color: var(--bs-tertiary-bg)
}
